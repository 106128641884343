import React, { useState, useEffect } from "react"
import { Form, Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory, useParams } from "react-router-dom"
import firebase from "../firebase";

export default function Bookings() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [days, setDays] = useState('');
  const [roomType, setRoomType] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [score, setScore] = useState('');

  const ref = firebase.firestore().collection('bookings');

//   async function handleLogout() {
//     setError("")

//     try {
//       await logout()
//       history.push("/login")
//     } catch {
//       setError("Failed to log out")
//     }
//   }

  function getBookings() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        console.log(items)
        setBookings(items);
        setLoading(false);
      });
  }

//   function getBookings2() {
//     setLoading(true);
//     ref.get().then((item) => {
//       const items = item.docs.map((doc) => doc.data());
//       setBookings(items);
//       setLoading(false);
//     });
//   }


function editBooking(booking) {
  console.log("booking edit", booking)
    const updatedBooking = {
      name: name?name:booking.name,
      days: days? days: booking.days
    };
    setLoading();
    ref
      .doc(booking.id)
      .update(updatedBooking)
      .catch((err) => {
        console.error(err);
      });
  }


  useEffect(() => {
    getBookings();
    // getBookings2();
    // eslint-disable-next-line
  }, []);


  return (
    <>
          {loading ? <h1>Loading...</h1> : null}
          <Card className="my-4">
          <img className="px-4 py-4 mx-4 my-4" style={{height:' 75%',width: '75%'}} src="https://crowntown.web.app/img/crown-logo.b650d4cd.png" />
        <h2 className="text-center my-4">Bookings</h2>
        </Card>
        {/* {
        bookings.map((booking)=>(
            <div className="py-2">
            <Card key={booking.id}>
            <Card.Body>
              <h2 className="text-left mb-4">{booking.name}</h2>
              <strong>Days:</strong> {booking.days}
              <Button className="btn btn-primary w-100 mt-3">
                Update Booking
              </Button>
            </Card.Body>
            </Card>
          
            </div>
            ))
        } */}
       {
        bookings.map((booking)=>(
         
      <Card className="my-4">
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {/* <Form >
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" defaultValue={booking.name}  onChange={(e) => setName(e.target.value)} required />
            </Form.Group>
            <Form.Group id="mobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control type="text" defaultValue={booking.mobileNumber}  onChange={(e) => setMobileNumber(e.target.value)} required />
            </Form.Group>
            <Form.Group id="roomType">
              <Form.Label>Room Type</Form.Label>
              <Form.Control type="text" defaultValue={booking.roomType}  onChange={(e) => setRoomType(e.target.value)} required />
            </Form.Group>
            <Form.Group id="days">
              <Form.Label>Days</Form.Label>
              <Form.Control type="text" defaultValue={booking.days}  onChange={(e) => setDays(e.target.value)} required />
            </Form.Group>
            {/* <Button style={{backgroundColor:"#b17500"}}  onClick={() => editBooking(booking)} className="w-100">
              Update
            </Button> 
          </Form> */}
          <Form >
            <Form.Group id="name">
              <Form.Label>Name: {booking.name}</Form.Label>
            </Form.Group>
            <Form.Group id="mobileNumber">
              <Form.Label>Mobile Number: {booking.mobileNumber}</Form.Label>
            </Form.Group>
            <Form.Group id="roomType">
              <Form.Label>Room Type: {booking.roomType}</Form.Label>
            </Form.Group>
            <Form.Group id="days">
              <Form.Label>Days: {booking.days}</Form.Label>
            </Form.Group>
            <Form.Group id="id">
              <Form.Label>National ID / Passport: {booking.id}</Form.Label>
            </Form.Group>
            {/* <Button style={{backgroundColor:"#b17500"}}  onClick={() => editBooking(booking)} className="w-100">
              Update
            </Button> */}
          </Form>
          {/* <div className="w-100 text-center mt-3">
            <Link to="/bookings">Cancel</Link>
          </div> */}
        </Card.Body>
      </Card>
 ))
}
    </>
  )
}
