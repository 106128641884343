import React, { useState, useEffect } from "react"
import { Form, Card, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory, useParams } from "react-router-dom"
import firebase from "./../firebase";

export default function Rooms() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [score, setScore] = useState('');

  const ref = firebase.firestore().collection('rooms');

//   async function handleLogout() {
//     setError("")

//     try {
//       await logout()
//       history.push("/login")
//     } catch {
//       setError("Failed to log out")
//     }
//   }

  function getRooms() {
    setLoading(true);
    ref.onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        });
        console.log(items)
        setRooms(items);
        setLoading(false);
      });
  }

//   function getRooms2() {
//     setLoading(true);
//     ref.get().then((item) => {
//       const items = item.docs.map((doc) => doc.data());
//       setRooms(items);
//       setLoading(false);
//     });
//   }


function editRoom(room) {
  console.log("room edit", room)
    const updatedRoom = {
      name: name?name:room.name,
      price: price? price: room.price
    };
    setLoading();
    ref
      .doc(room.id)
      .update(updatedRoom)
      .catch((err) => {
        console.error(err);
      });
  }


  useEffect(() => {
    getRooms();
    // getRooms2();
    // eslint-disable-next-line
  }, []);


  return (
    <>
          {loading ? <h1>Loading...</h1> : null}
          <Card className="my-4">
          <img className="px-4 py-4 mx-4 my-4" style={{height:' 75%',width: '75%'}} src="https://crowntown.web.app/img/crown-logo.b650d4cd.png" />
        <h2 className="text-center my-4">Rooms</h2>
        </Card>
        {/* {
        rooms.map((room)=>(
            <div className="py-2">
            <Card key={room.id}>
            <Card.Body>
              <h2 className="text-left mb-4">{room.name}</h2>
              <strong>Price:</strong> {room.price}
              <Button className="btn btn-primary w-100 mt-3">
                Update Room
              </Button>
            </Card.Body>
            </Card>
          
            </div>
            ))
        } */}
       {
        rooms.map((room)=>(
         
      <Card className="my-4">
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form >
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" defaultValue={room.name}  onChange={(e) => setName(e.target.value)} required />
            </Form.Group>
            <Form.Group id="price">
              <Form.Label>Price</Form.Label>
              <Form.Control type="text" defaultValue={room.price}  onChange={(e) => setPrice(e.target.value)} required />
            </Form.Group>
            <Button style={{backgroundColor:"#b17500"}}  onClick={() => editRoom(room)} className="w-100">
              Update
            </Button>
          </Form>
          {/* <div className="w-100 text-center mt-3">
            <Link to="/rooms">Cancel</Link>
          </div> */}
        </Card.Body>
      </Card>
 ))
}
    </>
  )
}
