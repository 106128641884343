import firebase from "firebase/app"
import "firebase/auth";
import "firebase/firestore"

const app = firebase.initializeApp({
  apiKey: "AIzaSyCU80Hc1iZ4Cx_qYl4Heb4Jm7kFOQl4DAA",
  authDomain: "crowntown-936b8.firebaseapp.com",
  databaseURL:"https://crowntown-936b8.firebaseio.com",
  projectId: "crowntown-936b8",
  storageBucket: "crowntown-936b8.appspot.com",
  messagingSenderId: "738202493783",
  appId: "1:738202493783:web:550433ea0f9f4510fcd412",
  measurementId: "G-66H33NEJY7"

})
  
  export const auth = app.auth()
  export default app
  